import React from 'react';
import { Divider, List, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import MailAction from './MailAction';

const MailActions = ({ actions }) => (
  <List>
    {actions.map(({ email, id, updatedAt, sendGridData }) => (
      <Stack key={id}>
        <MailAction
          id={`${id}mail`}
          email={email}
          updatedAt={updatedAt}
          sendGridData={sendGridData}
        />
        <Divider />
      </Stack>
    ))}
  </List>
);

MailActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      updatedAt: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
};

MailActions.defaultProps = {
  actions: [{
    id: 0,
    updatedAt: null,
    email: '',
  }],
};

export default MailActions;
