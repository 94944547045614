import moment from 'moment';
import React from 'react';
import { Step, StepLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FiberManualRecord } from '@mui/icons-material';

const EventStep = ({ event, title, color, ...props }) => (
  <Step
    sx={{
      textAlign: 'center',
      width: '128px',
      '& .Mui-completed': {
        color: 'success.main',
      },
      '& .Mui-active': {
        color: `${color}.main`,
      },
    }}
    {...props}
  >
    <Typography>{title}</Typography>
    <StepLabel icon={<FiberManualRecord />}> {event?.createdAt ? moment(event?.createdAt).format('DD/MM/YYYY') : ''} </StepLabel>
  </Step>

);

EventStep.propTypes = {
  event: PropTypes.shape({
    createdAt: PropTypes.string,
    status: PropTypes.string }),
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

EventStep.defaultProps = {
  color: 'success',
  event: {
    createdAt: null,
    status: '',
  },
};

export default EventStep;
