import { Dialog } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@mui/styles';
import Chat from './Chat';

const useStyles = makeStyles(() => createStyles({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 10,
  },
  messagesBody: {
    width: '-webkit-fill-available',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    scrollbarWidth: 'none',
    padding: '10px 15px',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: 0,
    },
    backgroundColor: '#f9f9f9',
  },
  dialogContainer: {
    width: '100vw',
    height: '90%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f4',
  },
  pencilIcon: {
    height: '1.3em',
  },
}));

const ChatDialog = ({ open, onClose, invoice, dataToRows, headers }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      classes={{
        paperWidthLg: classes.dialogContainer,
        paperWidthMd: classes.dialogContainer,
      }}
    >
      <Chat
        open={open}
        invoice={invoice}
        dataToRows={dataToRows}
        headers={headers}
      />
    </Dialog>
  );
};

ChatDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    unreadMessages: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    binnacleSet: PropTypes.array,
  }),
  dataToRows: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      extraDataKey: PropTypes.string,
      extraDataKey2: PropTypes.string,
      isSearchable: PropTypes.bool,
      align: PropTypes.string,
      size: PropTypes.number,
      disableOrderBy: PropTypes.bool,
      disablePadding: PropTypes.bool,
    }),
  ),
};

ChatDialog.defaultProps = {
  invoice: null,
  dataToRows: undefined,
  headers: undefined,
};

export default ChatDialog;
