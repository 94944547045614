import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import reactStringReplace from 'react-string-replace';
import moment from 'moment';
import { GET_USER } from '../../graphql';
import { formatTime, formatDayWords } from '../../helpers';

const useStyles = makeStyles((theme) => createStyles({
  messageRow: {
    display: 'flex',
  },
  messageRowRight: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  message: {
    position: 'relative',
    marginBottom: '10px',
    padding: '18px',
    minWidth: '30%',
    maxWidth: '60%',
    textAlign: 'left',
    border: 'none',
    borderRadius: '10px',
  },
  messageOther: {
    backgroundColor: theme.palette.primary.light,
    borderTopLeftRadius: 0,
  },
  messageOwn: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 0,
    border: '1px solid #323232',
  },
  messageInfo: {
    backgroundColor: '#efefef',
  },
  messageTimeStampRight: {
    position: 'absolute',
    fontSize: '.85em',
    fontWeight: '300',
    marginTop: '10px',
    bottom: '-3px',
    right: '5px',
  },
}));

const markupMessage = (message, isOwnMessage, isInfoMessage) => {
  const regex = /@\[([a-zA-Z0-9.@_-]*)\]\([0-9]+\)/g;
  const replacer = (match) => (
    <Typography
      variant="subtitle1"
      component="span"
      color="primary"
      style={{ display: 'inline', fontWeight: 'bold' }}
    >
      {match}
    </Typography>
  );
  const colorChoicer = () => {
    if (isInfoMessage) {
      return {
        color: 'auto',
        textAlign: 'center',
      };
    }
    if (isOwnMessage) {
      return {
        color: 'auto',
        textAlign: 'auto',
      };
    }
    return {
      color: 'auto',
      textAlign: 'auto',
    };
  };
  return (
    <Typography variant="subtitle1" style={colorChoicer()}>
      {reactStringReplace(message, regex, replacer)}
    </Typography>
  );
};

const Message = ({ invoiceMessage, previousMessage }) => {
  const { data: { getUser: { id: ownId } } } = useQuery(GET_USER);
  const classes = useStyles();
  const { user, message } = invoiceMessage;
  const isOwnMessage = user && user.id === ownId;
  const isInfoMessage = !user;
  const firstUserMessage = !previousMessage || !previousMessage.user || (
    user && previousMessage.user.id !== user.id);
  const aligner = useCallback(() => {
    if (isInfoMessage) return 'center';
    if (isOwnMessage) return 'flex-end';
    return 'flex-start';
  }, [isOwnMessage, isInfoMessage]);
  const classNameChoicer = useCallback(() => {
    if (isInfoMessage) return classes.messageInfo;
    if (isOwnMessage) return classes.messageOwn;
    return classes.messageOther;
  }, [isInfoMessage, isOwnMessage, classes]);
  const showDate = useCallback(() => {
    if (!previousMessage) return true;
    const previousDate = moment(previousMessage.createdAt);
    const nowDate = moment(invoiceMessage.createdAt);
    if (previousDate.year() !== nowDate.year()
    || previousDate.month() !== nowDate.month()
    || previousDate.date() !== nowDate.date()) {
      return true;
    }
    return false;
  }, [invoiceMessage.createdAt, previousMessage]);
  return (
    <Grid container direction="column" alignItems={aligner()}>
      {showDate() && (
        <Grid container item alignItems="center" justifyContent="center" style={{ padding: 20 }}>
          {formatDayWords(moment(invoiceMessage.createdAt))}
        </Grid>
      )}
      {user && (
        <Grid container style={{ display: firstUserMessage ? 'inherit' : 'none' }} item alignItems="center" justifyContent={aligner()}>
          <Grid item>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{user.firstName} {' '} {user.lastName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{'\u00A0\u00A0'}{formatTime(moment(invoiceMessage.createdAt))}</Typography>
          </Grid>
        </Grid>
      )}
      {!user && (
        <Grid container style={{ display: firstUserMessage ? 'inherit' : 'none' }} item alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant="subtitle1">{formatTime(moment(invoiceMessage.createdAt))}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        className={classNameChoicer()}
        classes={{ root: classes.message }}
      >
        {markupMessage(message, isOwnMessage, isInfoMessage)}
      </Grid>
    </Grid>
  );
};

Message.propTypes = {
  invoiceMessage: PropTypes.shape({
    message: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  previousMessage: PropTypes.shape({
    message: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

Message.defaultProps = {
  previousMessage: null,
};

export default Message;
