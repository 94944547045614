import React from 'react';
import PropTypes from 'prop-types';
import DrawerHeader from '../drawer/DrawerHeader';
import { ActionDrawerCell } from '../cells';
import MailActions from './MailActions';

const MailActionsButton = ({ invoice, showTitle }) => {
  const mailsSent = invoice.mailInvoices.map((node, index) => (
    { ...node, email: node.mailParameter.mail.emailName, id: index }
  ));
  return (
    <ActionDrawerCell
      title={`Factura ${invoice.folio}`}
      subtitle="Mails envíados sobre la factura"
      actions={mailsSent}
      headerComponent={DrawerHeader}
      contentComponent={MailActions}
      disabled={!invoice.mailInvoices.length}
      iconText={showTitle && 'Gestiones'}
    />

  );
};

MailActionsButton.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    unreadMessages: PropTypes.number,
    binnacleSet: PropTypes.arrayOf(PropTypes.shape()),
    folio: PropTypes.string,
    mailInvoices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      createdAt: PropTypes.instanceOf(Date),
      status: PropTypes.shape({ status: PropTypes.string }),
      mailParameter: PropTypes.shape({
        mail: PropTypes.shape({
          emailName: PropTypes.string,
        }),
      }),
    })),
  }),
  showTitle: PropTypes.bool,
};

MailActionsButton.defaultProps = {
  invoice: {
    mailInvoices: [],
  },
  showTitle: false,
};

export default MailActionsButton;
