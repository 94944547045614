import React, { useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { Mention } from 'react-mentions';
import CustomMentionsInput from '../inputs/CustomMentionsInput';

const useStyles = makeStyles((theme) => createStyles({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    backgroundColor: '#f9f9f9',
    marginTop: 15,
    width: '-webkit-fill-available',
    padding: '10px 15px',
  },
  mentionStyling: {
    color: theme.palette.primary.main,
  },
}));

const TextInput = ({ onSend, userMentions }) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [mentions, setMentions] = useState([]);
  const sendMessage = () => {
    onSend(message.trim(), mentions.map((value) => value.id));
    setMessage('');
  };
  return (
    <Paper className={classes.container}>
      <CustomMentionsInput
        id="standard-text"
        placeholder="Escribe tu mensaje aquí"
        autoFocus
        value={message}
        onChange={(_, newValue, __, newMentions) => {
          setMessage(newValue);
          setMentions(newMentions);
        }}
        allowSuggestionsAboveCursor
        onKeyDown={(event) => {
          if (message.trim() && event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
          } else if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <Mention
          trigger="@"
          data={userMentions}
          displayTransform={(_, display) => `${display}`}
          appendSpaceOnAdd
          className={classes.mentionStyling}
        />
      </CustomMentionsInput>
    </Paper>
  );
};

TextInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  userMentions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    display: PropTypes.string,
  })),
};

TextInput.defaultProps = {
  userMentions: [],
};

export default TextInput;
