import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { ListItem, Stack, Stepper, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Mail } from '@mui/icons-material';
import { makeStyles, withStyles } from '@mui/styles';
import _ from 'lodash';
import emailTranslator from '../../helpers/email-translator';
import EventStep from './EventStep';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiStepIcon-root': {
      color: 'gray',
      fontSize: '16px',
    },
    '& .MuiStepLabel-label': {
      color: 'gray',
      fontSize: '8px',
      margin: 3,
    },
    '& .MuiStepConnector-line': {
      borderWidth: 2,
      marginTop: 12,
      margin: 'auto',
    },
  },
}));

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'gray',
    border: '0.5px solid gray',
  },
})(Tooltip);

const MailAction = ({ email, id, updatedAt, sendGridData }) => {
  const getEvent = useCallback((callback) => (_.find(_.sortBy(sendGridData?.events, 'created_at'), callback)));
  const click = useMemo(() => getEvent(({ status }) => status === 'CLICK'));
  const open = useMemo(() => getEvent(({ status }) => status === 'OPEN'));
  const delivered = useMemo(() => getEvent(({ status }) => status === 'DELIVERED'));
  const failed = useMemo(() => getEvent(({ status }) => ['DROPPED', 'DROP', 'BOUNCE'].includes(status)));

  const [firstStepTitle, firstStepColor, firstStepEvent] = useMemo(() => {
    const otherSteps = open?.status || click?.status;
    if (otherSteps || delivered?.status) {
      return ['Envíado', 'success', delivered];
    }
    if (failed?.status) {
      return ['No llegó', 'error', failed];
    }
    return ['En proceso', 'warning', {}];
  });

  const activeStep = useMemo(() => {
    if (click?.status) {
      return 2;
    }
    if (open?.status) {
      return 1;
    }
    return 0;
  });

  const styles = useStyles();
  return (
    <StyledTooltip
      arrow
      placement="left"
      title={(
        <Stepper
          className={styles.root}
          alternativeLabel
          activeStep={activeStep}
        >
          <EventStep
            title={firstStepTitle}
            color={firstStepColor}
            event={firstStepEvent}
          />
          <EventStep
            title="Abierto"
            event={open}
          />
          <EventStep
            title="Click"
            event={click}
          />
        </Stepper>
            )}
    >
      <ListItem sx={{ marginBottom: 1 }} id={id}>
        <Stack>
          <Stack alignItems="center" direction="row" spacing={1} mb={1}>
            <Mail color="primary" />
            <Typography>{emailTranslator(email)} destino a {sendGridData?.toEmail}</Typography>
          </Stack>
          <Typography variant="caption" noWrap overflow="visible">
            {moment(updatedAt).format('DD-MM-YYYY HH:mm[hrs]')}
          </Typography>
        </Stack>
      </ListItem>
    </StyledTooltip>
  );
};

MailAction.propTypes = {
  id: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sendGridData: PropTypes.shape({
    toEmail: PropTypes.string,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        status: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default MailAction;
