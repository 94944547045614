import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

const FileChip = ({ file, uploadable, clickeable, size, selectedFileIds, setSelectedFileIds }) => {
  const { id, avatar, disabled, icon, label, url } = file;

  const isSelected = () => selectedFileIds.find((selectedFileId) => selectedFileId === id);

  const onClick = () => {
    if (isSelected()) {
      setSelectedFileIds(selectedFileIds.filter((selectedFileId) => selectedFileId !== id));
    } else {
      setSelectedFileIds([...selectedFileIds, id]);
    }
  };

  const onDelete = () => {
    if (uploadable && !url) {
      console.log('Se puede subir un archivo');
    } else {
      window.open(url, 'blank');
    }
  };

  return (
    <Chip
      id={id}
      avatar={avatar}
      clickable={clickeable}
      color={isSelected() ? 'primary' : 'default'}
      deleteIcon={uploadable && !url ? <FileUploadRoundedIcon /> : <OpenInNewIcon />}
      disabled={disabled}
      icon={icon}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      size={size}
      variant={isSelected() ? 'filled' : 'outlined'}
    />
  );
};

FileChip.propTypes = {
  uploadable: PropTypes.bool,
  clickeable: PropTypes.bool,
  size: PropTypes.string,
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
    avatar: PropTypes.element,
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    url: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  selectedFileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedFileIds: PropTypes.func.isRequired,
};

FileChip.defaultProps = {
  uploadable: false,
  clickeable: true,
  size: 'medium',
};

export default FileChip;
